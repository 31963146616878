import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';

import { vMaska } from "maska"
import i18n from "./i18n"
import MSALWrapper from "./authUtils";

import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"
import 'vue-loading-overlay/dist/css/index.css';

// PINIA
import pinia from '@/state/pinia'


let msalUtils = new MSALWrapper(window.location.origin);


import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import ApiServices from './services/apiservices';
import OrganisationApiService from './services/organisationapiservices';
import AppConfig from "./app.config.json";

let apiService = new ApiServices({
  apiUrl: AppConfig.api,
  getTokenFunction: async () => {return await msalUtils.getToken()}
})
let organisationApiService = new OrganisationApiService({
  organisationApiUrl: AppConfig.organisation_api,
  getTokenFunction: async () => {return await msalUtils.getOrganisationApiToken()}
})
createApp(App)
  .use(pinia)
  .use(router)
  .use(require('vue-chartist'))
  .use(BootstrapVueNext)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .directive("maska", vMaska)
  .provide('$apiServices', apiService)
  .provide('$organisationApiServices', organisationApiService)
  .provide('$msal', msalUtils)
  .mount('#app');
