<template>
 <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  page: {
    // All subcomponent titles will be injected into this template.
    // eslint-disable-next-line no-unused-vars
    titleTemplate(title) {
      let documentTitle = this.$route.meta.title;
      return documentTitle ? `${this.$t("titles." + documentTitle)} | ${this.$t("titles.portal")}` : this.$t("titles.portal");
    },
  },
  watch: {
    locale: function () {
      localStorage.setItem('appPortalLocale', this.$i18n.locale);
      document.title = this.documentTitle;
    }
  },
  computed: {
    locale: function(){
      return this.$i18n.locale;
    },
    documentTitle: function() {
      let title = this.$route.meta.title;
      return title ? `${this.$t("titles." + title)} | ${this.$t("titles.portal")}` : this.$t("titles.portal");
    }
  }
}
</script>
