export default [
  {
    path: '/404',
    name: '404',
    meta: {
      authRequired: false
    },
    component: () => import('../views/errors/404')
  },
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
      permissionRequired: false
    },
    component: () => import('../views/home/default'),
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    meta: {
      authRequired : false
    }
  },
  {
    path: '/accounts',
    name: 'accounts',
    meta: {
      authRequired: true,
      permissionRequired: true,
      title: "accountsmanagement"
    },
    component: () => import('../views/accounts/default'),
  },
  {
    path: '/organisation',
    name: 'organisation',
    meta: {
      authRequired: true,
      permissionRequired: true,
      title: "organisation"
    },
    component: () => import('../views/organisation/organisation'),
  }
]