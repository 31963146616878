import { PublicClientApplication } from '@azure/msal-browser';
import axios from "axios";
import i18n from './i18n';
import Router from './router'

export default class MSALWrapper {

  authConfig = {
    auth: {
      clientId: 'e007687a-c838-40e5-a59b-2afa8741e780',
      authority: 'https://webcab.b2clogin.com/tfp/webcab.onmicrosoft.com/B2C_1_Signin',
      knownAuthorities: ['https://webcab.b2clogin.com']
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  };

  currentAccount = null;

  accessToken = "";
  idToken = "";
  msalInstance = {};

  scopes = ["https://webcab.onmicrosoft.com/4c33ac4f-0a1c-4558-b6e3-f6de5cabe637/access"]

  tokenRequest = {
    scopes: this.scopes
  }

  loginRequest = {
    scopes: this.scopes,
    extraQueryParameters: { ui_locales: i18n.locale }
  }

  passwordResetRequest = {
    scopes: this.scopes,
    authority: 'https://webcab.b2clogin.com/tfp/webcab.onmicrosoft.com/B2C_1_PasswordReset',
    extraQueryParameters: { ui_locales: i18n.locale }
  }
  
  constructor(redirectUrl) {
    this.authConfig.auth.redirectUri = redirectUrl;
    this.msalInstance = new PublicClientApplication(this.authConfig);
  }

  async init(authRequired) {
    try {
      let response = await this.msalInstance.handleRedirectPromise();
      await this.handleResponse(response, authRequired);
    } catch(response) {
      if (response.errorMessage.includes("AADB2C90118")) {
        this.msalInstance.loginRedirect(this.passwordResetRequest);
      } else if (response.errorMessage.includes("AADB2C90091")) {
        // password reset cancelled by user
        this.msalInstance.loginRedirect(this.loginRequest);
      } else {
        throw response;
      }
    }
  }

  async getToken() {
    if (this.msalInstance.controller.initialized === false) {
      await this.msalInstance.initialize();
    }
    let request = {
      scopes: this.scopes,
      account: this.currentAccount
    }
    try {
      let response = await this.msalInstance.acquireTokenSilent(request);
      this.accessToken = response.accessToken;

      return response.accessToken;
    } catch (error) {
      console.log(error)
      await this.msalInstance.loginRedirect(this.loginRequest);
    }
  }

  async getOrganisationApiToken() {
    if (this.msalInstance.controller.initialized === false) {
      await this.msalInstance.initialize();
    }
    let request = {
      scopes: ["https://webcab.onmicrosoft.com/348aabfb-cce8-42b8-b603-4668b98102d7/MyOrganisation"],
      account: this.currentAccount
    }

    try {
      let response = await this.msalInstance.acquireTokenSilent(request)
      return response.accessToken;
    } catch (error) {
      await this.msalInstance.loginRedirect(this.loginRequest);
    }
  }

  setTokenResponse(response) {
    this.accessToken = response.accessToken;
    this.idToken = response.idToken;

    Object.assign(axios.defaults, { headers: { authorization: "Bearer " + this.accessToken } });
  }

  SignIn = () => {
    this.msalInstance.loginRedirect(this.loginRequest)
  }

  SignOut = async () => {
    let logoutRequest = {
      account: this.msalInstance.getActiveAccount()
    }
    this.msalInstance.logoutRedirect(logoutRequest);
  }

  ResetPassword = async () => {
    localStorage.setItem('appPortalResetPassword', true);
    this.msalInstance.loginRedirect(this.passwordResetRequest);
  }

  handleResponse = async (response, authRequired) => {
    if (response !== null) {
      this.setTokenResponse(response);
      this.msalInstance.setActiveAccount(response.account);
      this.currentAccount = response.account;
      Router.replace('/');
    } else if (authRequired) {
      if (this.currentAccount === null) {
        await this.msalInstance.loginRedirect(this.loginRequest);
      } else {
        let request = this.msalInstance.SilentRequest = {
          scopes: this.scopes,
          account: this.currentAccount
        };
        try {
          let response = await this.msalInstance.acquireTokenSilent(request);
          this.setTokenResponse(response);
        } catch {
          await this.msalInstance.loginRedirect(this.loginRequest);

        }
      }
    }
  }
}