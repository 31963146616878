import axios from 'axios';

export default class OrganisationApiService {
    constructor(options) {
        this.$organisationApiClient = axios.create({
            withCredentials: true,
            baseURL: options.organisationApiUrl,
        });
        var assignMsalToken = async (config) => {

            const token = await options.getTokenFunction();
            if (token) {
                Object.assign(config, {
                    headers: { authorization: 'Bearer ' + token },
                });
            }
            return config;
        }
        this.$organisationApiClient.interceptors.request.use(assignMsalToken, (error) =>
            Promise.reject(error)
        );
    }

    async getMyOrganisation() {
        return await this.$organisationApiClient.get("organisations/my");
    }

    createMyOrganisation(myOrganisation) {
        return this.$organisationApiClient.post("organisations/my", myOrganisation);
    }

    updateMyOrganisation(myOrganisation) {
        return this.$organisationApiClient.put(`organisations/my/${myOrganisation.id}`, myOrganisation);
    }
}