import axios from 'axios';

export default class ApiServices {
    constructor(options) {
        this.axiosClient = axios.create({
            withCredentials: true,
            baseURL: options.apiUrl
        });
        var assignMsalToken = async (config) => {
            //verif expiration
            const token = await options.getTokenFunction();
            if (token) {
                Object.assign(config, {
                    headers: { authorization: 'Bearer ' + token },
                });
            }
            return config;
        };
        this.axiosClient.interceptors.request.use(
            assignMsalToken,
            (error) => Promise.reject(error)
        );
    }
    getApplicationsAccess() {
        return this.axiosClient.get("applicationsaccess/");
    }
    getUsers() {
        return this.axiosClient.get("users/");
    }
    getApplicationsAvailability() {
        return this.axiosClient.get("applicationsavailability/");
    }
    addAccess(userid, access) {
        return this.axiosClient.post("users/addaccess", { user: userid, access: access });
    }
    removeAccess(userid, access) {
        return this.axiosClient.post("users/removeaccess", { user: userid, access: access });
    }
    deleteUser(userid) {
        return this.axiosClient.post("users/delete", { userid: userid });
    }
    createUser(request) {
        return this.axiosClient.post("users/create", request);
    }
    editName(userid, name) {
        return this.axiosClient.post("users/editusername", { userid: userid, name: name });
    }
}